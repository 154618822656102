import { t } from "i18next";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Img from "./Image";

const ProjectCard = (props) => {
  const style1 = {
    transform: `translateX(${props.slideX}%)`,
    transition: "0.4s",
  };

  return (
    <div style={style1} className="project--card border">
      <div className="project--img">
        <Img value={props.img} />
      </div>
      <div className="project--builtwith">
        <h2 className="fade-in-right">&#60; {t("builtwith")} &#62;</h2>
        <div>
          {props.map.map((item) => {
            return (
              <img
                key={item.name}
                src={item.src}
                className="project--icon fade-in-right"
              />
            );
          })}
        </div>
      </div>
      <div>
        <h2 className="fade-in-right">&#60; {props.project} &#62;</h2>
        <p className="p fade-in-right">{props.description}</p>
        <a href={props.live} target="_blank" className="link--button border">
          {t("live")}
        </a>
        {props.github && (
          <a
            href={props.github}
            target="_blank"
            className="link--button border"
          >
            {t("code")}
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
