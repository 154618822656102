import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p>© Copyright by Leigh Yang
        . All Right Reserved.</p>
    </div>
  );
};

export default Footer;
