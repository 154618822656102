import React, { useState } from "react";
import ParticlesBackground from "../ParticlesBackground";
import "../../assets/home.css";
import SocialMedia from "../SocialMedia";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Home = (props) => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  const shouldHideComponent = windowWidth < 768;

  return (
    <>
      <header className="header">
        {shouldHideComponent ? null : (
          <ParticlesBackground theme={props.theme} />
        )}
        <h1 className="name--home fade-in">{t("home_name")}</h1>
        <div className="home--container fade-in-slow">
          <p className="typed-out anim-typewriter">{t("occ")}</p>
        </div>
        <div>
          <Link to="/about">
            <i className="fa-solid fa-angles-down icon home"></i>
          </Link>
        </div>
        <p className="fade-in-slower">{t("intro")}</p>
        <SocialMedia />
      </header>
    </>
  );
};

export default Home;
