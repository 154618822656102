import React from "react";

const Card = (props) => {
  return (
    <div className="about--card fade-in-right border">
      <h1 className="card--h1">
        &#60;
        <span className="card--h1"> {props.h1} </span>
        &#62;
      </h1>
      <div className="about--imgs">{props.lis}</div>
    </div>
  );
};

export default Card;
