import React, { useState } from "react";
import i18next from "i18next";
import "../assets/lang.css";

const Lang = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "zh",
      name: "Chinese Tradition",
      country_code: "tw",
    },
  ];
  const lang = languages.map((i) => {
    if (i.code !== currentLanguage) {
      return (
        <div
          key={i.country_code}
          className={`${i.code} lang--icon`}
          onClick={() => {
            i18next.changeLanguage(i.code);
            setCurrentLanguage(i.code);
          }}
        ></div>
      );
    }
  });
  return (
    <div className="lang">
      <button className={`${currentLanguage} lang--icon`} />
      <div className="dropdown">{lang}</div>
    </div>
  );
};

export default Lang;
