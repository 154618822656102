import React from "react";

const SocialMedia = (props) => {
  const socialArray = [
    {
      id: 1,
      icon: "fa-brands fa-github icon",
      url: "https://github.com/leigh79712",
    },
    {
      id: 2,
      icon: "fa-brands fa-linkedin icon",
      url: "https://linkedin.com/in/leighleelin",
    },
    {
      id: 3,
      icon: "fa-brands fa-codepen icon",
      url: "https://codesandbox.io/u/leigh79712",
    },
  ];

  const socialMap = socialArray.map((icon) => {
    return (
      <a key={icon.id} href={icon.url} target="_blank">
        <i className={icon.icon}></i>
      </a>
    );
  });
  return <div className="socialicon">{socialMap}</div>;
};

export default SocialMedia;
