import React, { useRef, useState } from "react";
import Layout from "../layout/Layout";
import Img from "../Image";
import shoppingappimg from "../../assets/video/shoppingapp.gif";
import vic from "../../assets/video/vic.gif";
import nodejs from "../../assets/images/nodejs.png";
import mongodb from "../../assets/images/mongo.png";
import css from "../../assets/images/css.png";
import html from "../../assets/images/html-5.png";
import javascript from "../../assets/images/javascript.png";
import ProjectCard from "../ProjectCard";
import bankappimg from "../../assets/video/bankapp.gif";
import nextjs from "../../assets/images/nextjs.png";
import ejs from "../../assets/images/ejs.jpeg";
import react from "../../assets/images/physics.png";
import bootstrap from "../../assets/images/Bootstrap.png";
import { useTranslation } from "react-i18next";
import "../../assets/project.css";
import { Link } from "react-router-dom";

const Project = () => {
  const { t } = useTranslation();
  const [slideX, setSlideX] = useState(0);
  const [curSlide, setCurSlide] = useState(0);
  const project = [
    {
      img: vic,
      project: t("vic"),
      description: t("vicdiscription"),
      icon: [{ name: "react", src: react },
      { name: "css", src: css },
      { name: "javascript", src: javascript },],

      live: "http://victsai.com",
    },
    {
      img: shoppingappimg,
      project: t("shoppingapp"),
      description: t("shoppingappdiscription"),
      icon: [
        { name: "html", src: html },
        { name: "css", src: css },
        { name: "javascript", src: javascript },
        { name: "nodejs", src: nodejs },
        { name: "mongodb", src: mongodb },
        { name: "ejs", src: ejs },
        { name: "bootstrap", src: bootstrap }
      ],
      github: "https://github.com/leigh79712/Petite-BAE",
      live: "https://petite-bae.onrender.com/",
    },
    {
      img: bankappimg,
      project: t("bankapp"),
      icon: [
        { name: "mongodb", src: mongodb },
        { name: "nextjs", src: nextjs },
        { name: "react", src: react },
        { name: "css", src: css },
        { name: "javascript", src: javascript },
      ],
      description: t("bankappdiscription"),
      github: "https://github.com/leigh79712/fake-bank-app",
      live: "https://fake-bank-app-leigh79712.vercel.app/zh-TW",
    },
  ];
  const maxSlide = project.length;
  const handleNextSlide = (num) => {
    if (curSlide === maxSlide - 1) {
      setCurSlide(0);
      setSlideX(0);
    } else {
      setCurSlide((pre) => pre + 1);
      setSlideX((pre) => (pre += num));
    }
  };
  const handlePrevSlide = (num) => {
    if (curSlide === 0) {
      setCurSlide(maxSlide - 1);
      setSlideX(num * -(maxSlide - 1));
    } else {
      setCurSlide((pre) => pre - 1);
      setSlideX((pre) => (pre += num));
    }
  };

  return (
    <Layout>
      <h1 className="header--h1"> &#60; {t("project")} &#62;</h1>
      <div className="project">
        {project.map((item, i) => {
          return (
            <ProjectCard
              slideX={slideX}
              key={i}
              img={item.img}
              project={item.project}
              map={item.icon}
              description={item.description}
              github={item.github}
              live={item.live}
            />
          );
        })}
      </div>

      <button
        onClick={() => handlePrevSlide(110)}
        className="angle-left button--angle"
      >
        <i className="fa-solid fa-angle-left"></i>
      </button>

      <button
        onClick={() => handleNextSlide(-110)}
        className="angle-right button--angle"
      >
        <i className="fa-solid fa-angle-right"></i>
      </button>
    </Layout>
  );
};

export default Project;
