import React, { useState } from "react";
import Home from "./components/page/Home";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/page/About";
import Project from "./components/page/Project";
import Contact from "./components/page/Contact";
import useLocalStorage from "use-local-storage";

import Lang from "./components/Lang";

const App = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  return (
    <div data-theme={theme}>
      <button className="theme--context" onClick={switchTheme}>
        <i
          className={
            theme === "dark"
              ? "fa-solid fa-sun sun darkMode"
              : "fa-solid fa-moon darkMode"
          }
        ></i>
      </button>
      <Lang />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home theme={theme} />} />
          <Route path="/about" element={<About />} />
          <Route path="/project" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
