import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/styles.css";
import "../assets/nav.css";
const Navbar = () => {
  const [clickNav, setClickNav] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [url, setUrl] = useState(null);
  const linksArray = [
    { name: "home", url: "/", icon: "fa-house" },
    { name: "about", url: "/about", icon: "fa-user" },
    { name: "project", url: "/project", icon: "fa-file" },
    { name: "contact", url: "/contact", icon: "fa-envelope" },
  ];

  const links = linksArray.map((link) => {
    return (
      <Link className="nav--link fade-in-right" to={link.url} key={link.name}>
        {link.name}
      </Link>
    );
  });
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  const icons = linksArray.map((link) => {
    return (
      <Link className="nav--link" to={link.url} key={link.name}>
        <div className={url === link.url ? "active" : ""}>
          <span className={`fa-solid ${link.icon} icon`}></span>
          <span className="link--text">{link.name}</span>
        </div>
      </Link>
    );
  });

  const handleNavbar = () => {
    setClickNav(!clickNav);
  };

  useEffect(() => {
    let timer;
    if (clickNav) {
      setTimeout(() => {
        setShow(true);
      }, 600);
    } else {
      setShow(false);
    }
    return () => clearTimeout(timer);
  }, [clickNav]);

  return (
    <>
      <nav className={clickNav ? "nav" : "nav--close"}>
        {!clickNav && (
          <i
            onClick={handleNavbar}
            className={`fa-solid fa-bars icon ${clickNav ? "hidden" : ""}`}
          ></i>
        )}

        {!clickNav && <div className="nav--links">{icons}</div>}
        <div
          className={`${show ? "social-open" : "social-closed"} ${
            clickNav ? "" : "none"
          }`}
        >
          <p className={`nav--p`}>
            &#60;
            <span className="nav--logo"> Leigh </span>
            &#62;
          </p>
          <div className="nav--links">{links}</div>
        </div>
      </nav>

      <div className={clickNav ? "backdrop" : ""} onClick={handleNavbar}></div>
    </>
  );
};

export default Navbar;
