import React from "react";
import "../../assets/styles.css";
import Layout from "../layout/Layout";
import Image from "../Image";
import img from "../../assets/images/portfolio.jpeg";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import "../../assets/about.css";
import html from "../../assets/images/html-5.png";
import css from "../../assets/images/css.png";
import javascript from "../../assets/images/javascript.png";
import react from "../../assets/images/physics.png";
import sass from "../../assets/images/sass.png";
import nodejs from "../../assets/images/nodejs.png";
import mongodb from "../../assets/images/mongo.png";
import github from "../../assets/images/github.png";
import figma from "../../assets/images/Figma.png";
import fork from "../../assets/images/fork.png";
import vscode from "../../assets/images/Vscode.png";
import bootstrap from "../../assets/images/Bootstrap.png";
import postman from "../../assets/images/postman.png";
import nextjs from "../../assets/images/nextjs.png";
import { Link } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();

  const skills = [
    { name: "html", src: html },
    { name: "css", src: css },
    { name: "javascript", src: javascript },
    { name: "react", src: react },
    { name: "sass", src: sass },
    { name: "nodejs", src: nodejs },
    { name: "mongodb", src: mongodb },
  ];
  const devtools = [
    { name: "github", src: github },
    { name: "figma", src: figma },
    { name: "bootstrap", src: bootstrap },
    { name: "postman", src: postman },
    { name: "vscode", src: vscode },
    { name: "fork", src: fork },
    { name: "nextjs", src: nextjs },
  ];
  const items = skills.map((item) => {
    return <img key={item.name} src={item.src} className="about--img" />;
  });
  const devItems = devtools.map((item) => {
    return <img key={item.name} src={item.src} className="about--img" />;
  });
  return (
    <Layout>
      <div className="about--container">
        <div className="img-box">
          <Image value={img} />
        </div>
        <div className="about--info fade-in-left">
          <div className="about--info--name">
            <h1>{t("about_name")}</h1>
            <p className="info--occ">{t("occ")}</p>
            <p className="info--loca">
              <span>
                <i className="fa-solid fa-location-dot"></i>
              </span>
              {t("location")}
            </p>
          </div>

          <div className="about--links--button">
            <a
              href={t("cv")}
              target="_blank"
              className="link--button border link--button--background"
            >
              {t("resume")}
            </a>
            <Link
              className="link--button border link--button--background"
              to="/contact"
              key="contact"
            >
              {t("contact")}
            </Link>
          </div>
          <div className="about--p">
            <p className="p">{t("about_info")}</p>
          </div>
        </div>
        <Card h1={t("skills")} lis={items} />
        <Card h1={t("devtools")} lis={devItems} />
      </div>
    </Layout>
  );
};

export default About;
