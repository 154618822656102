import React, { useRef } from "react";
import Layout from "../layout/Layout";
import "../../assets/styles.css";
import Button from "../Button";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kyr0rym",
        "template_en8llou",
        form.current,
        "lOWFaD8wb5agRvUGO"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <Layout>
      <h1 className="header--h1 fade-in-right-header">
        &#60; {t("contact")} &#62;
      </h1>
      <div className="contact--container">
        <div className="contact--left fade-in-right">
          <p className="info--loca contact--loca">
            <i className="fa-solid fa-location-dot "></i>
            {t("location")}
          </p>
          <p className="contact--email">
            <i className="fa-solid fa-at"></i>
            leigh79712@hotmail.com
          </p>
          <p className="contact--p">{t("contact_info")}</p>
        </div>
        <div className="contact--right">
          <form
            className="contact--form fade-in-left"
            ref={form}
            onSubmit={sendEmail}
          >
            <input
              className="contact--input"
              type="text"
              name="name"
              placeholder={t("name")}
            />
            <input
              className="contact--input"
              type="email"
              name="email"
              placeholder={t("email")}
            />
            <input
              className="contact--input"
              type="text"
              name="subject"
              placeholder={t("subject")}
            />
            <textarea
              className="contact--input"
              name=""
              id=""
              cols="30"
              rows="10"
              name="message"
              placeholder={t("letschat")}
            ></textarea>
            <Button type="submit" value={t("send")} />
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
