import React from "react";
import "../assets/styles.css";

const Image = (props) => {
  return (
    <img
      className="img fade-in-right"
      alt="leighleelin, 網頁設計。網站設計。官網設計。網站製作。前端工程師"
      src={props.value}
      alt=""
    />
  );
};

export default Image;
