import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import SocialMedia from "../SocialMedia";

const Layout = (props) => {
  return (
    <div className="layout">
      <Navbar />
      <main className="container">
        {props.children}
        <SocialMedia />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
