import React from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const ParticlesBackground = (props) => {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  const particlesConfig = {
    fpsLimit: 60,
    particles: {
      color: {
        value: props.theme === "light" ? "#444444" : "#fff",
      },
      links: {
        enable: true,
        color: props.theme === "light" ? "#444444" : "#fff",
        distance: 150,
      },
      size: {
        value: 3,
        random: true,
        anim: {
          enable: false,
          speed: 50,
          size_min: 0.1,
          sync: false,
        },
      },
      move: {
        enable: true,
      },
      opacity: {
        value: 0.3,
      },
    },
  };

  return (
    <div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        params={particlesConfig}
      />
    </div>
  );
};

export default ParticlesBackground;
